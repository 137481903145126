@import 'datatables.net-dt';

body,
html {
    height: 100%;
    margin: 0;
}

main.content {
    height: 100vh;
}

@media only screen and (max-width: 768px) {
    main.content {
        height: auto;
    }
}

label.form-label {
    font-size: 14px !important;
}

input.form-control,
select.form-select {
    font-size: large !important;
    padding: 0.4rem 0.75rem !important;
}

ol li,
ul li,
.p {
    font-size: unset !important;
}

div.card-header {
    padding: 1rem !important
}

div.card-body {
    padding: 20px !important;
    height: 100%;
}

.partial-custom-scroll-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.top,
.bottom {
    flex: 0 0 auto;
}

.btn-custom {
    padding: 6px 10px !important;
    font-size: large;
}

.mini-hr {
    margin: 10px !important;
}

.full-rest-heigh-scroll {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden !important
}


.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* width */
.small-scrollbar::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.small-scrollbar::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
.small-scrollbar::-webkit-scrollbar-thumb {
    background: #585858;
}

/* Handle on hover */
.small-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #4b4b4b;
}


.nav-tabs,
.tab-content,
.btn {
    font-size: 12px !important;
}

.nav-tabs .nav-link {
    padding: .5rem;
}


/* ANTD */
/* Custom Arrow Styles */
.custom-carousel .slick-prev, 
.custom-carousel .slick-next {
    color: #2D0C57 !important;  /* Ubah warna arrow */
    font-size: 24px !important;
    z-index: 1;
}

/* Hover Effect */
.custom-carousel .slick-prev:hover, 
.custom-carousel .slick-next:hover {
    color: #2D0C57 !important;
}

/* Custom Dot Styles */
.custom-carousel .slick-dots li button {
    background: #A680B8 !important; /* Ubah warna dot */
    opacity: 1;
}

.custom-carousel .slick-dots li.slick-active button {
    background: #2D0C57 !important; /* Warna aktif */
}
